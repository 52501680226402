import { useForm, zodResolver } from '@mantine/form';
import z from 'zod';
import NotificationUtils from '../Utils/NotificationUtils';
import useLoadTracker from './useLoadTracker';
import klaviyoService from '../Services/KlaviyoService';

const validationSchema = z.object({
	name: z.string().max(512).optional(),
	email: z.string().email('Invalid email address').nonempty('Email is required'),
});

export type NewsletterSignupValues = {
	name?: string;
	email: string;
};

export default function useNewsletterSignup() {
	const { addLoader, removeLoader, isLoading } = useLoadTracker();

	const form = useForm<NewsletterSignupValues>({
		initialValues: { name: '', email: '' },
		validate: zodResolver(validationSchema),
	});

	const handleFormSubmit = async (values: NewsletterSignupValues) => {
		const loader = addLoader();
		try {
			await klaviyoService.subscribeToNewsletter(values.email, values.name || '');
			NotificationUtils.showSuccess('Successfully subscribed to the mailing list!', 'Mailing list subscription');
			form.reset();
		} catch (err) {
			NotificationUtils.showError(err as Error, 'Error subscribing to the mailing list');
		} finally {
			removeLoader(loader);
		}
	};

	return { form, handleFormSubmit, isLoading };
}
