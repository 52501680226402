export class KlaviyoService {
	private static readonly LIST_NEWSLETTER = 'WeCBgG';
	private static readonly URL_SUBSCRIBE = 'https://manage.kmail-lists.com/ajax/subscriptions/subscribe';

	public async subscribeToNewsletter(email: string, name: string) {
		const nameParts = name.split(/\s+/);

		const firstName = nameParts.shift() ?? '';
		const lastName = nameParts.length ? nameParts.join(' ') : '';

		const response = await fetch(KlaviyoService.URL_SUBSCRIBE, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: new URLSearchParams({
				g: KlaviyoService.LIST_NEWSLETTER,
				email: email,
				$first_name: firstName,
				$last_name: lastName,
				$fields: '$first_name,$last_name',
			}),
		});

		if (response.ok) {
			const responseBody = await response.json();

			if (responseBody.success) {
				return true;
			}
		}

		throw new Error('Failed to subscribe to newsletter.');
	}
}

export type SubscribeToNewsletterResponse = {
	data: {
		is_subscribed: boolean;
		email?: string;
	};
	success: boolean;
	errors: string[];
};

const klaviyoService = new KlaviyoService();
export default klaviyoService;
